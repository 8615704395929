import Link from 'next/link';

import { useLocationState } from '../context/location';
import { useObjectsDispatch } from '../context/objects';

import Logo from './../public/images/logo.svg';

const Footer = ({ region, seo }) => {
  const locationState = useLocationState();
  const setObjectsState = useObjectsDispatch();

  if (!region && locationState.code)
    region = locationState.code;

  if (region == undefined || region == "")
    region = process.env.NEXT_PUBLIC_DEFAULT_REGION;

  return (
    <footer>
      <section className="footer">
        <div className="top">
          <div className="socials">
            <a
              href="https://t.me/baza_estate"
              className="telegram no-animation"
              title="Telegram"
              target="_blank"
              rel="noreferrer"
            />
          </div>
          <Link href={['/', region ? region + '/' : ''].join('')} passHref>
            <div className="logo">
              <Logo />
            </div>
          </Link>
        </div>
        <ul className="middle">
          <li> Продажа
            <ul className="submiddle">
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/buy/flats/1k'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'продажа', category: 'квартира', rooms: ['1'] }
                      })
                    }
                  >
                    Однокомнатные квартиры
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/buy/flats/2k'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'продажа', category: 'квартира', rooms: ['2'] }
                      })
                    }
                  >
                    Двухкомнатные квартиры
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/buy/flats/3k'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'продажа', category: 'квартира', rooms: ['3'] }
                      })
                    }
                  >
                    Трехкомнатные квартиры
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/buy/rooms'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'продажа', category: 'комната' }
                      })
                    }
                  >
                    Комнаты
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/buy/houses'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'продажа', category: 'дом' }
                      })
                    }
                  >
                    Домовладения
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/buy/sites'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'продажа', category: 'участок' }
                      })
                    }
                  >
                    Земельные участки
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/buy/commercial'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'продажа', category: 'коммерция' }
                      })
                    }
                  >
                    Коммерческая недвижимость
                  </a>
                </Link>
              </li>
            </ul>

          </li>
          <li> Аренда
            <ul className="submiddle">
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/rent/flats/1k'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'аренда', category: 'квартира', rooms: ['1'] }
                      })
                    }
                  >
                    Однокомнатные квартиры
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/rent/flats/2k'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'аренда', category: 'квартира', rooms: ['2'] }
                      })
                    }
                  >
                    Двухкомнатные квартиры
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/rent/flats/3k'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'аренда', category: 'квартира', rooms: ['3'] }
                      })
                    }
                  >
                    Трехкомнатные квартиры
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/rent/rooms'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'аренда', category: 'комната' }
                      })
                    }
                  >
                    Комнаты
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/rent/houses'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'аренда', category: 'дом' }
                      })
                    }
                  >
                    Домовладения
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/rent/sites'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'аренда', category: 'участок' }
                      })
                    }
                  >
                    Земельные участки
                  </a>
                </Link>
              </li>
              <li>
                <Link href={['/', region ? region + '/' : '', 'objects/rent/commercial'].join('')} passHref>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      setObjectsState({
                        type: 'SET_FILTER',
                        payload: { type: 'аренда', category: 'коммерция' }
                      })
                    }
                  >
                    Коммерческая недвижимость
                  </a>
                </Link>
              </li>
            </ul>

          </li>
          <li> Полезное
            <ul className="submiddle">
              {/* <li>
                <Link href="/mortgage" passHref>
                  <a>Ипотека</a>
                </Link>
              </li> */}
              {/* <li>
            <Link href="/services" passHref>
              <a>Услуги</a>
            </Link>
          </li> */}
              <li>
                <Link href={['/', region ? region + '/' : '', 'buildings'].join('')} passHref>
                  <a>Новостройки</a>
                </Link>
              </li>
              { <li>
            <Link href={['/', region ? region + '/' : '', 'add'].join('')} passHref>
              <a>Добавить объект</a>
            </Link>
          </li>
          /*<li>
            <Link href="/contacts" passHref>
              <a>Контакты</a>
            </Link>
          </li>*/}
              <li>
                <Link href="/xml" passHref>
                  <a>Подключить фид</a>
                </Link>
              </li>
              <li>
                <Link href="/faq/policy" passHref>
                  <a>Политика конфиденциальности</a>
                </Link>
              </li>
            </ul>

          </li>


        </ul>

        {seo && (
          <div className="seo">
            <h1>{seo}</h1>
          </div>
        )}

        {/* <div className="bottom">
          <Image src="/images/logo.svg" width={150} height={30} alt="" />
        </div> */}
      </section>
    </footer>
  );
};

export default Footer;
